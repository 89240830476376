import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import { Pagination } from 'vant';
import { Icon } from 'vant';
import { Toast } from 'vant';

import axios from 'axios'

Vue.use(Icon);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Toast);

Vue.config.productionTip = false;


// 根据路由设置标题
router.beforeEach((to, from, next) => {
  /*路由发生改变修改页面的title */
  if(to.meta.title) {
    document.title = to.meta.title
  }
  next();
})

new Vue({
  router,
  axios,
  store,
  render: h => h(App)
}).$mount('#app')
